import React, { FC } from "react";
import { Avatar, Button, Typography } from "antd";
import styles from "./ChatMsgGroup.module.less";
import { ZRCMessage } from "../../../rocketChat/rocketChat.types";
import { ChatMsg } from "./ChatMsg/ChatMsg";
import { ChatFormStore } from "../../ChatFormStore";
import { createChatMsg } from "../../createChatMsg";

type Props = {
  msgGroup: ZRCMessage[];
  store: ChatFormStore;
};

export const ChatMsgGroup: FC<Props> = ({ msgGroup, store }) => (
  <div className={styles.msgGroup}>
    <div className={styles.msgGroupContent}>
      {msgGroup.map(({ msg, attachments, _id, u }) => (
        <div key={_id} className={styles.msgGroupMsgList}>
          <div>
            <Avatar icon={u?.username[0]} />
          </div>
          <div>
            <div className={styles.userName}>
              <Typography.Text type="secondary">{u?.username}</Typography.Text>
            </div>
            <ChatMsg
              key={_id}
              attachments={
                attachments?.map((a, i) => ({ ...a, key: `${_id}:${i}` })) || []
              }
              msg={createChatMsg(msg, Object.values(store.personsDict))}
            />
            <Button
              type="text"
              onClick={() => store.setReplyMsgId(_id)}
              className={styles.reply}
            >
              <Typography.Text type="secondary">Reply</Typography.Text>
            </Button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

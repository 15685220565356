import React from "react";
import { classNames } from "src/common/classNames";
import { ZRCAttachment } from "../../../../rocketChat/rocketChat.types";
import { defineAttachment } from "../defineAttachment/defineAttachment";
import styles from "./ChatMsg.module.less";

type TypeAttachmentDef = ZRCAttachment & {
  key: React.Key;
};

type PropsChatMsg = {
  msg: React.ReactNode;
  attachments: TypeAttachmentDef[];
  className?: string;
};

export const ChatMsg: React.FC<PropsChatMsg> = ({
  attachments,
  msg,
  className,
}) => (
  <div className={classNames([styles.box, className])}>
    {msg && <div>{msg}</div>}
    {attachments?.map((item) => (
      <React.Fragment key={item.key}>{defineAttachment(item)}</React.Fragment>
    ))}
  </div>
);

ChatMsg.defaultProps = {
  className: undefined,
};
